import React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import TrackableCTA from 'components/utils/TrackableCTA';
import * as style from 'styles/components/subPartners/list.module.scss';

const PartnerBoxes = ({ headline, boxSize, partners }) => {
    const boxSizes = {
        'xl': style.subPartnersList__boxContainer__xl,
        'lg': style.subPartnersList__boxContainer__lg,
        'md': style.subPartnersList__boxContainer__md,
        'sm': style.subPartnersList__boxContainer__sm,
    }
    return (
        <>
            <h3 className="title-xs-mobile title-sm text-semibold">
                {headline}
            </h3>
            <div className={`${boxSizes[boxSize]}`}>
                {partners.map((partner, index) => (
                    <div key={index} className={style.subPartnersList__box}>
                        <TrackableCTA
                            ctaText={partner.title}
                            location={`Partner page - ${headline}`}
                            destination={partner.url}
                        >
                            <a href={partner.url} target="_blank" rel="noreferrer">
                                <ImageGatsby
                                    {...partner.logo}
                                    alt={partner.title}
                                    title={partner.title}
                                />
                            </a>
                        </TrackableCTA>
                    </div>
                ))}
            </div>
        </>
    );
};

export default PartnerBoxes;
